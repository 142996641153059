import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
	FaBell,
	FaCheck,
	FaBatteryEmpty,
	FaBatteryQuarter,
	FaBatteryHalf,
	FaBatteryThreeQuarters,
	FaBatteryFull,
	FaBluetooth,
	FaPlus,
	FaClock
} from "react-icons/fa";
import { handleModelIcon, handleIllustration } from "../Illustrations";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import useDateBeforeOrAfter from "../../../hooks/useDateBeforeOrAfter";
import * as p from "../../../constants/Permissions";
import { UserInterface } from "../../../interfaces/UserInterface";
import { DiaperSeniorInterface } from "../../../interfaces/DiaperInterface";

interface Props {
	setShowDiaperChange: (arg: { show: boolean; info: any; title: string }) => void;
	setShowRemoveSensor: (arg: { show: boolean; info: any; title: string; success?: boolean }) => void;
	setShowAffectSensor: (arg: { show: boolean; info: any; title: string }) => void;
	userState: UserInterface;
	seniorsToDisplay: DiaperSeniorInterface[];
	isControlPanel: boolean;
}

export default function AlertCardsContainer({
	setShowDiaperChange,
	setShowRemoveSensor,
	setShowAffectSensor,
	userState,
	seniorsToDisplay,
	isControlPanel
}: Props): JSX.Element | null {
	const { t } = useTranslation("common");
	const isDiaperManager = userState.userConnected.is(p.DIAPER_APPLICATION) && userState.userConnected.home_id;
	const today = new Date();
	const yesterday = useDateBeforeOrAfter({ isBefore: true, numberOfDays: 1 });

	const isToday = (date: Date): boolean => {
		return (
			date.getDate() === today.getDate() &&
			date.getMonth() === today.getMonth() &&
			date.getFullYear() === today.getFullYear()
		);
	};

	const isYesterday = (date: Date): boolean => {
		return (
			date.getDate() === yesterday.getDate() &&
			date.getMonth() === yesterday.getMonth() &&
			date.getFullYear() === yesterday.getFullYear()
		);
	};

	const getCurrentHHMM = (date: Date): number => {
		const hour = date.getHours();
		const minutes = date.getMinutes();
		return hour * 60 + minutes;
	};

	const displayIcon = (senior: DiaperSeniorInterface): JSX.Element => {
		const lastChange = senior.currentChange ? senior.currentChange.split(" ")[0] : null;
		if (senior.alert) {
			return <FaBell className="diaper-alert-icon saturation" />;
		} else if (senior.provisions.length > 0) {
			if (lastChange && senior.currentChange) {
				const currentTime = getCurrentHHMM(today);
				const seniorProvisionsTiming = senior.provisions.map(provision => provision.start_time);
				const seniorProvisionsTimingReversed = senior.provisions.map(provision => provision.start_time).reverse();
				const currentRangeHHMM = seniorProvisionsTimingReversed.find(range => currentTime >= range);
				const lastChangeDate = new Date(senior.currentChange.split(" ").join("T"));
				const lastChangeHHMM = getCurrentHHMM(lastChangeDate);
				const currentRangePosition = currentRangeHHMM ? seniorProvisionsTiming.indexOf(currentRangeHHMM) : null;
				const lastProvisionPosition = seniorProvisionsTiming.length - 1;
				const isTodayLastChange = lastChange ? isToday(new Date(lastChange)) : false;
				if (currentRangePosition === 0) {
					if (isTodayLastChange && lastChangeHHMM >= seniorProvisionsTiming[0]) {
						return <FaCheck className="diaper-alert-icon check" />;
					} else {
						return <FaClock className="diaper-alert-icon dotation" />;
					}
				} else if (currentRangePosition === lastProvisionPosition) {
					if (isTodayLastChange && lastChangeHHMM >= seniorProvisionsTiming[lastProvisionPosition]) {
						return <FaCheck className="diaper-alert-icon check" />;
					} else {
						return <FaClock className="diaper-alert-icon dotation" />;
					}
				} else {
					if (currentRangeHHMM && lastChangeHHMM >= currentRangeHHMM) {
						if (isTodayLastChange) {
							return <FaCheck className="diaper-alert-icon check" />;
						} else {
							return <FaClock className="diaper-alert-icon unchanged" />;
						}
					} else if (currentRangeHHMM && lastChangeHHMM <= currentRangeHHMM) {
						return <FaClock className="diaper-alert-icon unchanged" />;
					} else if (currentTime <= seniorProvisionsTiming[0]) {
						const isYesterdayLastChange = lastChange ? isYesterday(new Date(lastChange)) : false;
						if (isYesterdayLastChange || isTodayLastChange) {
							return <FaCheck className="diaper-alert-icon check" />;
						} else {
							return <FaClock className="diaper-alert-icon dotation" />;
						}
					} else {
						return <FaCheck className="diaper-alert-icon other" />;
					}
				}
			} else {
				return <FaCheck className="diaper-alert-icon other" />;
			}
		} else if (lastChange) {
			const isTodayLastChange = lastChange ? isToday(new Date(lastChange)) : false;
			if (isTodayLastChange) {
				return <FaCheck className="diaper-alert-icon check" />;
			} else {
				return <FaCheck className="diaper-alert-icon other" />;
			}
		} else {
			return <FaCheck className="diaper-alert-icon other" />;
		}
	};

	const displayColorCard = (senior: DiaperSeniorInterface): string => {
		if (senior.state === 1) {
			return "diaper-card alert";
		} else if (senior.sensor_id) {
			return "diaper-card blue";
		} else {
			return "diaper-card grey";
		}
	};

	const displayBLevel = (level: number): JSX.Element => {
		switch (level) {
			case 0:
				return <FaBatteryEmpty style={{ color: "#fa5456", height: "1.4rem", width: "auto" }} />;
			case 1:
				return <FaBatteryEmpty style={{ color: "#959da5", height: "1.4rem", width: "auto" }} />;
			case 2:
				return <FaBatteryQuarter style={{ color: "#959da5", height: "1.4rem", width: "auto" }} />;
			case 3:
				return <FaBatteryHalf style={{ color: "#959da5", height: "1.4rem", width: "auto" }} />;
			case 4:
				return <FaBatteryThreeQuarters style={{ color: "#959da5", height: "1.4rem", width: "auto" }} />;
			case 5:
				return <FaBatteryFull style={{ color: "#959da5", height: "1.4rem", width: "auto" }} />;
			default:
				return <FaBatteryEmpty style={{ color: "#ececec", height: "1.4rem", width: "auto" }} />;
		}
	};

	return seniorsToDisplay.length > 0 ? (
		<>
			{seniorsToDisplay.map(senior => (
				<div className={displayColorCard(senior)} key={senior.id}>
					<div className="diaper-info-block">
						<div className="d-flex flex-row">
							<div>{displayIcon(senior)}</div>
							<div>
								<p className="senior-name blue">
									{isControlPanel ? (
										<>
											{senior.last_name.toUpperCase()} {senior.first_name}
										</>
									) : (
										<Link to={`/senior/profile/${senior.id}`}>
											{senior.last_name.toUpperCase()} {senior.first_name}
										</Link>
									)}
								</p>
								<span className="meta">
									{senior.home_unit_name ? senior.home_unit_name : null} {senior.room ? `- ${senior.room}` : null}
								</span>
							</div>
						</div>
						<div className="diaper-sat-info">
							{senior.lastControl ? (
								<p>
									{t("diaper.__control__")} :{" "}
									<span className="right">
										{senior.lastControl ? handleDate(senior.lastControl, "Pp", userState.userConnected.locale) : null}
									</span>
								</p>
							) : null}
							<p>
								{t("diaper.__lastSaturation__")} :{" "}
								<span className="right">
									{senior.lastSaturation ? handleDate(senior.lastSaturation, "Pp", userState.userConnected.locale) : null}
								</span>
							</p>
							<p>
								{t("diaper.__lastChange__")} :
								<span className="right">
									{senior.currentChange ? handleDate(senior.currentChange, "Pp", userState.userConnected.locale) : null}
								</span>
							</p>
						</div>
					</div>
					{isDiaperManager ? (
						<div
							className="change-diaper"
							onClick={(): void => setShowDiaperChange({ show: true, info: { senior: senior }, title: senior.name })}
							role="button">
							{senior.diaper ? (
								<div className="diaper-info d-flex justify-content-between">
									{handleIllustration(senior.diaper.brand, senior.diaper.line, senior.diaper.ref)}
									<p className="mt-2">
										<span className="right">{senior.diaper.name}</span>
										<br />
										<span className="right">{handleModelIcon(senior.diaper)}</span>
									</p>
								</div>
							) : (
								<p className="meta">{t("diaper.__noDiaper__")}</p>
							)}
						</div>
					) : (
						<div className="change-diaper-without-link">
							{senior.diaper ? (
								<div className="diaper-info">
									{handleIllustration(senior.diaper.brand, senior.diaper.line, senior.diaper.ref)}
									<span className="right meta mt-2">
										{senior.diaper.name}
										<br />
										<span className="right mt-1">{handleModelIcon(senior.diaper)}</span>
									</span>
								</div>
							) : (
								<p className="meta">{t("diaper.__noDiaper__")}</p>
							)}
						</div>
					)}

					{senior.sensor_mac_address ? (
						<div className="sensor-info-block">
							{senior.sensor_values ? <span className="right">{displayBLevel(senior.sensor_values.b_level)}</span> : null}

							{senior.sensor_mac_address ? (
								<div
									className="remove-sensor"
									onClick={(): void =>
										setShowRemoveSensor({ show: true, info: { senior: senior }, title: t("diaper.__sensor__"), success: false })
									}
									role="button">
									<FaBluetooth className="mr-1" />
									{senior.sensor_mac_address}
								</div>
							) : (
								<i>{t("diaper.__noSensor__")}</i>
							)}
						</div>
					) : (
						<div className="sensor-info-block">
							<div
								className="diaper-button blue"
								role="button"
								onClick={(): void =>
									setShowAffectSensor({
										show: true,
										info: { senior: senior },
										title: t("diaper.__affectSensor__")
									})
								}>
								<FaPlus /> <b>{t("diaper.__addSensor__")}</b>
							</div>
						</div>
					)}
				</div>
			))}
		</>
	) : null;
}
