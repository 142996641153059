import React from "react";
import { useTranslation } from "react-i18next";
import { FaAngleRight } from "react-icons/fa";
import { AverageProps, RatioProps } from "./DotationReducer";

interface Props {
	average: AverageProps | null;
	anatomicRate: RatioProps | null;
}

const DotationBudgetStatistics = ({ average, anatomicRate }: Props): JSX.Element => {
	const { t } = useTranslation("common");

	return (
		<div className="d-flex big-card">
			<div className="amd-goal">
				{average ? (
					<>
						<p className="amd-goal-meta">{t("diaper.__realCid__")}</p>
						<h2>
							{average.average} <span className="meta">€ {t("diaper.__ht__")}</span>
						</h2>

						<div className="amd-goal-block">
							<div className="d-flex justify-content-between">
								<p className="amd-goal-text" style={{ color: "#00a0e3" }}>
									<FaAngleRight /> {t("diaper.__changeNumber__")} :
								</p>
								<p className="amd-goal-text mr-4" style={{ color: "#00a0e3" }}>
									{average.nb}
								</p>
							</div>
							{average.nb_without_price > 0 ? (
								<div className="d-flex justify-content-between">
									<p className="amd-goal-text" style={{ color: "#00a0e3" }}>
										<FaAngleRight /> {t("diaper.__changeWithoutPrice__")} :
									</p>
									<p className="amd-goal-text mr-4" style={{ color: "#00a0e3" }}>
										{average.nb_without_price}
									</p>
								</div>
							) : null}

							<div className="d-flex justify-content-between">
								<p className="amd-goal-text" style={{ color: "#00a0e3" }}>
									<FaAngleRight /> Total :
								</p>
								<p className="amd-goal-text mr-4" style={{ color: "#00a0e3" }}>
									{average.sum} € {t("diaper.__ht__")}
								</p>
							</div>
						</div>
					</>
				) : null}
			</div>
			<div className="amd-goal"></div>
			<div className="amd-goal">
				{anatomicRate ? (
					<>
						<p className="amd-goal-meta">{t("diaper.__realAnatomicalRate__")}</p>
						<h2>
							{anatomicRate.percentage} <span className="meta">%</span>
						</h2>
						<div className="amd-goal-block">
							<div className="d-flex justify-content-between">
								<p className="amd-goal-text" style={{ color: "#00a0e3" }}>
									<FaAngleRight /> {t("diaper.__nber__")} :
								</p>
								<p className="amd-goal-text mr-3" style={{ color: "#00a0e3" }}>
									{anatomicRate.nb_form}
								</p>
							</div>
							<div className="d-flex justify-content-between">
								<p className="amd-goal-text" style={{ color: "#00a0e3" }}>
									<FaAngleRight /> Total :
								</p>
								<p className="amd-goal-text mr-3" style={{ color: "#00a0e3" }}>
									{anatomicRate.nb_total}
								</p>
							</div>
						</div>
					</>
				) : null}
			</div>
		</div>
	);
};
export default DotationBudgetStatistics;
