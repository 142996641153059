import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaAngleDoubleRight, FaExclamationCircle, FaPlusCircle, FaQuestionCircle } from "react-icons/fa";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import { Table } from "../../../utils/Table/TableWithFilter";
import { DiaperInterface } from "../../../interfaces/DiaperInterface";
import { HealthWorkerInterface } from "../../../interfaces/UserInterface";
import DisplayName from "../../../utils/DisplayName";

interface Props {
	statistics: {
		senior: string;
		type: string;
		date: string;
		change: { diaper_id: string; done_at: string } | null;
	}[];
	diapers: DiaperInterface[];
	userLocale: string;
	healthWorkers: HealthWorkerInterface[];
}

const DotationTableFiltered = ({ statistics, diapers, userLocale, healthWorkers }: Props): JSX.Element => {
	const { t } = useTranslation("common");

	const displayDiaper = (diaperId: number): string | null => {
		const diaperSelected = diapers.find(diaper => diaper.id === diaperId);
		return diaperSelected ? diaperSelected.name : null;
	};

	const handleIcon = (type: string): JSX.Element => {
		switch (type) {
			case "NOT_FOUND":
				return <FaQuestionCircle className="regular-icon yellow mr-2" />;
			case "EXTRA":
				return <FaPlusCircle className="regular-icon blue mr-2" />;
			default:
				return <FaExclamationCircle className="regular-icon red mr-2" />;
		}
	};

	const columns = [
		{
			Header: t("common.__date__"),
			accessor: "date",
			disableFilters: true,
			Cell: (row: any) => handleDate(new Date(row.value), "PP", userLocale)
		},
		{
			Header: t("common.__senior__"),
			accessor: "seniorName",
			disableFilters: true,
			Cell: (row: any) => (
				<Link to={`/senior/profile/${row.row.original.seniorId}`}>
					<span className="category-outline-badge blue">{row.row.values.seniorName}</span>
				</Link>
			)
		},
		{
			Header: t("diaper.__diaper__"),
			accessor: "type",
			disableFilters: true,
			Cell: (row: any) => (
				<>
					{row.row.values.type === "NOT_FOUND" ? (
						<>
							<p className="meta">{row.row.original.dotation.begin_time} : Dotation manquante</p>
							<p>
								{handleIcon(row.row.values.type)} {displayDiaper(row.row.original.dotation.diaper_id)}
							</p>
						</>
					) : null}
					{row.row.values.type === "FOUND_BUT_BAD" ? (
						<>
							<p className="meta">Dotation :</p>
							<p>
								{row.row.original.dotation.begin_time} <FaAngleDoubleRight />{" "}
								{displayDiaper(row.row.original.dotation.diaper_id)}
							</p>
							<p className="meta">
								{handleDate(row.row.original.change.done_at, "p", userLocale)} : Change réalisé par{" "}
								{DisplayName(row.row.original.change.user_id, healthWorkers)}
							</p>
							<p>
								{handleIcon(row.row.values.type)} {displayDiaper(row.row.original.change.diaper_id)}
							</p>
						</>
					) : null}
					{row.row.values.type === "EXTRA" ? (
						<>
							<p className="meta">
								{handleDate(row.row.original.change.done_at, "p", userLocale)} : Change réalisé par{" "}
								{DisplayName(row.row.original.change.user_id, healthWorkers)}
							</p>
							<p>
								{handleIcon(row.row.values.type)} {displayDiaper(row.row.original.change.diaper_id)}
							</p>
						</>
					) : null}
				</>
			)
		}
	];

	return statistics.length > 0 ? (
		<Table columns={columns} data={statistics} />
	) : (
		<p>
			<FaAngleDoubleRight className="mr-2" />
			{t("common.__noElement__")}
		</p>
	);
};
export default DotationTableFiltered;
