import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";

interface Props {
	homeId: number;
	userLocale: string;
}

interface GoalsProps {
	IndicateurCIJ: number;
	IndicateurCR: number;
	IndicateurTA: number;
	ObjectifCIJ: string;
	ObjectifCR: string;
	ObjectifTA: string;
	StatutCIJ: string;
	StatutCR: string;
	StatutTA: string;
	TypesProduitsCIJ: string;
	TypesProduitsCR: string;
	TypesProduitsTA: string;
	datetime: string;
}

const AmdGoals = ({ homeId, userLocale }: Props): JSX.Element | null => {
	const { t } = useTranslation("common");
	const [amdGoals, setAmdGoals] = useState<GoalsProps | null>(null);

	useEffect(() => {
		if (homeId) {
			http
				.get(`${url.HOMESURL}/${homeId}/amd_profile/last_management_tool`)
				.then(res => setAmdGoals(res.data))
				.catch(() => setAmdGoals(null));
		}
	}, [homeId]);

	const getHexColorName = (color: string): string => {
		switch (color) {
			case "Gris":
				return "LightSlateGrey";
			case "Orange":
				return "#ffae42";
			case "Rouge":
				return "#fa5456";
			case "Vert":
				return "#50c878";
			default:
				return "#00a0e3";
		}
	};

	return amdGoals ? (
		<>
			<>
				<div className="d-flex justify-content-between mt-2">
					<h5 className="mr-3">{t("hds.__goals__")} AMD Profil</h5>
					<p className="meta mt-2">
						{t("diaper.__dataSyncedOn__")} : {handleDate(amdGoals.datetime, "PPp", userLocale)}
					</p>
				</div>
				<div className="d-flex big-card">
					<div className="amd-goal">
						<p className="amd-goal-meta">
							<strong>{t("diaper.__cid__")}</strong> : {t("diaper.__cidInfo__")}
						</p>
						<h2>
							{amdGoals.IndicateurCIJ} <span className="meta">€ {t("diaper.__ht__")}</span>
						</h2>
						<div className="d-flex amd-goal-block">
							<div>
								<span
									className="amd-goal-dot mr-1"
									style={{
										backgroundColor: getHexColorName(amdGoals.StatutCIJ)
									}}></span>
							</div>
							<div className="amd-goal-text" style={{ color: getHexColorName(amdGoals.StatutCIJ) }}>
								{amdGoals.ObjectifCIJ}
							</div>
						</div>
						<p className="meta">{amdGoals.TypesProduitsCIJ}</p>
					</div>
					<div className="amd-goal">
						<p className="amd-goal-meta">
							<strong>C.R.</strong> : {t("diaper.__complianceReco__")}
						</p>
						<h2>
							{amdGoals.IndicateurCR} <span className="meta">%</span>
						</h2>

						<div className="d-flex amd-goal-block">
							<div>
								<span
									className="amd-goal-dot mr-1"
									style={{
										backgroundColor: getHexColorName(amdGoals.StatutCR)
									}}></span>
							</div>
							<div className="amd-goal-text" style={{ color: getHexColorName(amdGoals.StatutCR) }}>
								{amdGoals.ObjectifCR}
							</div>
						</div>
					</div>
					<div className="amd-goal">
						<p className="amd-goal-meta">
							<strong>{t("diaper.__ar__")}</strong> : {t("diaper.__anatomicalRate__")}
						</p>
						<h2>
							{amdGoals.IndicateurTA} <span className="meta">%</span>
						</h2>

						<div className="d-flex amd-goal-block">
							<div>
								<span
									className="amd-goal-dot mr-1"
									style={{
										backgroundColor: getHexColorName(amdGoals.StatutTA)
									}}></span>
							</div>
							<div className="amd-goal-text" style={{ color: getHexColorName(amdGoals.StatutTA) }}>
								{amdGoals.ObjectifTA}
							</div>
						</div>
						<p className="meta">{amdGoals.TypesProduitsTA}</p>
					</div>
				</div>
			</>
		</>
	) : null;
};
export default AmdGoals;
