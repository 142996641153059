import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaUserPlus } from "react-icons/fa";
import { HealthWorkerInterface, UserInterface } from "../../../interfaces/UserInterface";
import useStoreData from "../../../hooks/useStoreData";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import SelectHomeInput from "../../../layout/SelectHomeInput";
import IncoRecipients from "./IncoRecipients";
import HandleError from "../../../layout/HandleError";

interface Props {
	homeId: number | null;
	userState: UserInterface;
	isAdmin: boolean;
}

const IncoRecipientsContainer = ({ userState, homeId, isAdmin }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const { store } = useStoreData({ userState, homeId });
	const healthworkers = store.users.healthWorkers;
	const [recipientsIds, setRecipientsIds] = useState<number[]>([]);
	const [recipientsListUpdated, setRecipientsListUpdated] = useState(false);
	const recipients = healthworkers.filter((healthworker: HealthWorkerInterface) =>
		recipientsIds.includes(healthworker.id)
	);
	const [error, setError] = useState(false);
	const [addUser, setAddUser] = useState(false);

	useEffect(() => {
		if (homeId) {
			http
				.get(`${url.HOMESURL}/${homeId}/rht/saturation/notification/recipients`)
				.then(res => setRecipientsIds(res.data.user_ids))
				.catch(() => setError(true));
		}
	}, [homeId, recipientsListUpdated]);

	return (
		<div className="big-card">
			<div className="d-flex justify-content-between">
				<h2>{t("common.__notificationsRecipients__")}</h2>
				<div>
					{isAdmin ? (
						<button
							type="button"
							className={addUser ? "btn btn-sm mt-1 btn-outline-secondary" : "btn btn-sm mt-1 btn-outline-primary"}
							onClick={(): void => setAddUser(prevState => !prevState)}>
							{addUser ? (
								t("common.__back__")
							) : (
								<>
									<FaUserPlus className="mr-2" />
									{t("common.__add__")}
								</>
							)}
						</button>
					) : null}
				</div>
			</div>

			{homeId ? (
				error ? (
					<HandleError error={error} setError={setError} noSpace />
				) : recipientsIds.length > 0 ? (
					<IncoRecipients
						isAdmin={isAdmin}
						homeId={homeId}
						healthworkers={healthworkers}
						recipientsIds={recipientsIds}
						setRecipientsListUpdated={setRecipientsListUpdated}
						recipients={recipients}
						addUser={addUser}
						setAddUser={setAddUser}
					/>
				) : (
					<p className="mt-3 mb-5">{t("mailbox.__noRecipients__")}</p>
				)
			) : (
				<SelectHomeInput />
			)}
		</div>
	);
};
export default IncoRecipientsContainer;
