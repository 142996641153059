import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import SeniorWithDiaperSaturationChartContainer from "./SeniorWithDiaperSaturationChartContainer";
import Loader from "../../../layout/Loader";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";

const SeniorWithDiaperSaturationChart = ({ seniorId, startDate, startTime, endDate, endTime, userLocale }) => {
	const { t } = useTranslation("common");
	const [values, setValues] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (seniorId && startDate && endDate && startTime && endTime) {
			setIsLoading(true);
			const startDateFormatted = handleDate(startDate, "yyyy-MM-dd");
			const endDateFormatted = handleDate(endDate, "yyyy-MM-dd");
			const startTimeFormatted = handleDate(startTime, "HH:mm:ss");
			const endTimeFormatted = handleDate(endTime, "HH:mm:ss");
			const start = `${startDateFormatted} ${startTimeFormatted}`;
			const end = `${endDateFormatted} ${endTimeFormatted}`;

			http
				.get(
					`${url.SENIORSURL}/${seniorId}/rht/values/plus/plus/from/${encodeURIComponent(start)}/to/${encodeURIComponent(
						end
					)}`
				)
				.then(res => {
					setIsLoading(false);
					const data = res.data.transformed;
					const completedValues = data.map(val => {
						return {
							...val,
							d: handleDate(val.datetime, "Pp", userLocale)
						};
					});
					setValues(completedValues);
				})
				.catch(() => {
					setIsLoading(false);
					setValues([]);
				});
		}
	}, [seniorId, startDate, endDate, startTime, endTime, userLocale]);

	return (
		<div className="big-card">
			<h5>{t("diaper.__saturationChart__")}</h5>
			{isLoading ? (
				<Loader />
			) : values.length > 0 ? (
				<SeniorWithDiaperSaturationChartContainer values={values} />
			) : (
				<p className="meta">{t("common.__noDataAvailable__")}</p>
			)}
		</div>
	);
};

export default SeniorWithDiaperSaturationChart;
