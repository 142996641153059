import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FamilyInterface, NewSeniorInterface } from "../../../interfaces/UserInterface";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import { RoomInterface } from "../../../interfaces/HomeInterface";
import { SENIORSURL } from "../../../constants/Url";
import http from "../../../services/Interceptor";

interface Props {
	newSeniorStore: NewSeniorInterface;
	newSeniorDispatch: React.Dispatch<any>;
	homeId: number;
	dispatch: React.Dispatch<any>;
}

export default function AddSeniorResume({ newSeniorStore, newSeniorDispatch, homeId, dispatch }: Props): JSX.Element {
	const { t } = useTranslation("common");
	const history = useHistory();

	console.log(newSeniorStore);

	const room = newSeniorStore.room_id
		? newSeniorStore.rooms.find((room: RoomInterface) => room.id === newSeniorStore.room_id)
		: null;

	const saveNewSenior = (): void => {
		dispatch({
			type: "HIDE_ERROR"
		});
		newSeniorDispatch({
			type: "LOADING"
		});
		const existingFamiliesSelected = newSeniorStore.existingFamilyUserIds.map((familyId: number) => ({
			family_user_id: familyId
		}));
		const newFamiliesAdded = newSeniorStore.newFamilyUsers.map((family: FamilyInterface) => ({ family_user: family }));
		if (newSeniorStore.senior) {
			const seniorData = {
				senior: {
					arrival_date: newSeniorStore.senior.arrival_date,
					birth_date: newSeniorStore.senior.birth_date,
					first_name: newSeniorStore.senior.first_name,
					last_name: newSeniorStore.senior.last_name,
					gender: newSeniorStore.senior.gender,
					home_id: homeId,
					gir_level: newSeniorStore.senior.gir_level,
					profile_id: newSeniorStore.senior.profile_id
				},
				family_users: [...existingFamiliesSelected, ...newFamiliesAdded],
				room_id: newSeniorStore.room_id
			};
			http
				.post(`${SENIORSURL}`, seniorData)
				.then(res => {
					dispatch({
						type: "ADD_SENIOR",
						payload: res.data.senior
					});
					history.push(`/senior/profile/${res.data.senior.id}`);
				})
				.catch(err => {
					window.scrollTo(0, 0);
					newSeniorDispatch({
						type: "ERROR",
						payload:
							err.response.request.responseText === JSON.stringify({ errors: { login: ["has already been taken"] } })
								? t("senior.__addSeniorLoginError__")
								: err.response.request.responseText === JSON.stringify({ errors: { email: ["has already been taken"] } })
								? t("family.__userAlreadyExist__")
								: t("senior.__addSeniorError__")
					});
				});
		} else {
			newSeniorDispatch({
				type: "ERROR",
				payload: t("senior.__addSeniorError__")
			});
		}
	};

	return newSeniorStore.senior ? (
		<>
			<div className="row">
				<div className="col-4">
					<p>
						<b>{t("common.__senior__")}</b>
					</p>
					<p>{t("senior.__birthDate__")}</p>
					<p>{t("senior.__arrivalDate__")}</p>
					<p>{t("common.__room__")}</p>
					<p>{t("senior.__independant__")}</p>
				</div>
				<div className="col-8">
					<p>
						<b>
							{newSeniorStore.senior.first_name} {newSeniorStore.senior.last_name}
						</b>
					</p>
					<p>{handleDate(new Date(newSeniorStore.senior.birth_date), "PP", "fr")}</p>
					<p>{handleDate(new Date(newSeniorStore.senior.arrival_date), "PP", "fr")}</p>
					<p>{room ? room.name : null}</p>
					<p>
						{newSeniorStore.senior.profile_id === 1
							? t("senior.__independant__")
							: newSeniorStore.senior.gir_level
							? `GIR: ${newSeniorStore.senior.gir_level}`
							: null}
					</p>
				</div>
			</div>
			{newSeniorStore.newFamilyUsers.length > 0
				? newSeniorStore.newFamilyUsers.map((family, index) => (
						<div className="row mt-3" key={index}>
							<div className="col-4">
								<p>
									<b>{t("family.__principal__")}</b>
								</p>
								<p>{t("common.__mail__")}</p>
								<p>{t("common.__phone__")}</p>
							</div>
							<div className="col-8">
								<p>
									<b>
										{family.first_name} {family.last_name}
									</b>
								</p>
								<p>{family.email}</p>
								<p>{family.phone ? family.phone : "_"}</p>
							</div>
						</div>
				  ))
				: null}
			<button
				type="button"
				className="btn btn-outline-secondary btn-sm mt-3 mb-3 mr-2"
				onClick={(): void => newSeniorDispatch({ type: "GO_TO_FAMILY" })}>
				{t("common.__back__")}
			</button>
			<button type="button" className="btn btn-primary btn-sm mt-3 mb-3" onClick={saveNewSenior}>
				{t("common.__save__")}
			</button>
		</>
	) : (
		<p>{t("common.__errorOccured__")}</p>
	);
}
