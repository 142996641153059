import React from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

const MyInputNumber = props => {
	const { t } = useTranslation("common");
	const {
		register,
		formState: { errors }
	} = useFormContext();

	return (
		<div className="form-group mb-3">
			{props.label === undefined ? null : (
				<label htmlFor={props.name} className="form-label">
					{props.label}
				</label>
			)}
			<input
				placeholder={props.placeholder}
				id={props.id ? props.id : props.name}
				name={props.name}
				type="number"
				step={props.step ? props.step : 1}
				{...register(props.name, {
					required: props.behavior.required,
					pattern: props.behavior.pattern,
					maxLength: props.behavior.maxLength,
					min: props.behavior.min,
					max: props.behavior.max
				})}
				className="form-control form-control-sm"
				defaultValue={props.value || 0}
				onFocus={e => (e.target.placeholder = "")}
			/>

			{props.behavior.required ? (
				errors[props.name] ? null : (
					<small className="form-text text-muted right" id={`${props.name}Error`}>
						<span className="required">{t("common.__required__")}</span>
					</small>
				)
			) : null}

			{errors[props.name] ? (
				props.behavior.min && errors[props.name].type === "min" ? (
					<small className="red right mt-1">
						Valeur minimum autorisée : <b>{props.behavior.min}</b>.
					</small>
				) : null
			) : null}

			{errors[props.name] ? (
				props.behavior.max && errors[props.name].type === "max" ? (
					<small className="red right mt-1">
						Valeur maximum autorisée : <b>{props.behavior.max}</b>.
					</small>
				) : null
			) : null}

			{errors[props.name] ? (
				props.behavior.maxLength ? (
					props.behavior.maxLength === 2 ? (
						<small className="red mt-5">{t("common.__twoNumbersRequired__")}</small>
					) : props.behavior.maxLength === 3 ? (
						<small className="red mt-5">{t("common.__threeNumbersMax__")}</small>
					) : props.behavior.maxLength === 4 ? (
						<small className="red mt-5">{t("common.__fourNumbersRequired__")}</small>
					) : (
						<small className="red mt-5">{t("common.__numberRequired__")}</small>
					)
				) : props.behavior.pattern ? (
					<small className="red mt-5">{t("common.__numberRequired__")}</small>
				) : null
			) : null}
		</div>
	);
};

export default MyInputNumber;
