import React from "react";
import HandleLocale from "../HandleLocale";
import DatePicker from "react-datepicker";

interface Props {
	startTime: Date;
	setStartTime?: (startTime: Date) => void;
	userLocale: string;
	dispatch?: React.Dispatch<any>;
	type?: string;
}

const OnlyTimePicker = ({ startTime, setStartTime, userLocale, dispatch, type }: Props): JSX.Element => {
	const locale = HandleLocale(userLocale);

	return (
		<DatePicker
			selected={startTime}
			onChange={(date: Date): void =>
				setStartTime
					? setStartTime(date)
					: type === "END"
					? dispatch && dispatch({ type: "END_TIME", payload: date })
					: dispatch && dispatch({ type: "START_TIME", payload: date })
			}
			showTimeSelect
			showTimeSelectOnly
			dateFormat="p"
			timeFormat="p"
			locale={locale}
			timeIntervals={15}
			timeCaption="Heure"
			className="form-control form-control-sm mb-2"
		/>
	);
};
export default OnlyTimePicker;
