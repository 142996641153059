import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import HandleError from "../../../layout/HandleError";
import MyInputText from "../../../utils/Form/MyInputText";
import MyInputSelect from "../../../utils/Form/MyInputSelect";
import HeaderContainer from "../../../layout/HeaderContainer";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import * as p from "../../../constants/Permissions";
import NoData from "../../../layout/NoData";
import MyInputNumber from "../../../utils/Form/MyInputNumber";
import { UserInterface } from "../../../interfaces/UserInterface";
import { DiaperStoreInterface } from "../../../interfaces/DiaperInterface";

interface Props {
	homeId: number;
	homeSelected?: { name: string } | null;
	types: { id: number; name: string }[];
	userState: UserInterface;
	diaperStore: DiaperStoreInterface;
	diaperDispatch: React.Dispatch<any>;
}

interface FormValues {
	type: string;
	macAddress: string;
	temperature: string;
	humidity: string;
}

const AddSensor = ({ homeId, homeSelected, types, userState, diaperStore, diaperDispatch }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const history = useHistory();
	const methods = useForm<FormValues>({ mode: "onBlur" });
	const { handleSubmit } = methods;

	const onSubmit = (values: FormValues): void => {
		diaperDispatch({
			type: "HIDE_ERROR"
		});
		const data = {
			diaper_sensor: {
				type: values.type,
				mac_address: values.macAddress,
				home_id: homeId,
				delta_t: values.temperature,
				delta_h: values.humidity
			}
		};
		http
			.post(`${url.BASEURL}/rht/diaper_sensors`, data)
			.then(res => {
				diaperDispatch({
					type: "ADD_SENSOR",
					payload: res.data.diaper_sensor
				});
				history.push("/inco/sensor/diapersensors");
			})
			.catch(() => {
				diaperDispatch({
					type: "DISPLAY_ERROR",
					payload: t("common.__addError__")
				});
			});
	};

	return (
		<div className="big-card">
			<HeaderContainer
				title={t("diaper.__sensors__")}
				addButton={t("common.__add__")}
				link={"/inco/sensor/diapersensor/add"}
				permissionNeeded={[p.SUPER_ADMIN, p.ADMIN]}
				userState={userState}
			/>
			<HandleError error={diaperStore.error} dispatch={diaperDispatch} message={diaperStore.msg} />

			<h5 className="blue">{t("diaper.__addSensor__")} </h5>
			{types.length > 0 ? (
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<p className="meta mb-3">
							<b>
								{t("common.__home__")} : {homeSelected?.name}
							</b>
						</p>
						<MyInputSelect label={t("common.__type__")} name="type" behavior={{ required: true }} list={types} />
						<MyInputText label={`MAC address`} name="macAddress" behavior={{ required: true, maxLength: 17 }} />
						<div className="row">
							<div className="col-md-6">
								<MyInputNumber
									label={t("diaper.__humidityCalibration__")}
									name="humidity"
									behavior={{ required: true, max: 100, min: -100 }}
									placeholder="0"
									step="1"
								/>
							</div>
							<div className="col-md-6">
								<MyInputNumber
									label={t("diaper.__temperatureCalibration__")}
									name="temperature"
									behavior={{ required: true, max: 100, min: -100 }}
									placeholder="0"
									step="0.1"
								/>
							</div>
						</div>
						<button className="btn btn-primary btn-sm mt-2 mb-4" type="submit">
							{t("common.__save__")}
						</button>
					</form>
				</FormProvider>
			) : (
				<NoData />
			)}
		</div>
	);
};

export default AddSensor;
